import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import NavBar from "../components/navbar"
import SEO from "../components/SEO/index"
import Ymaps from "../components/Ymaps"
import { Section } from "../components/CompoundComponents/Section"
import { Columns, Column } from "../components/CompoundComponents/Columns"
import Footer from "../components/Footer/index"

const ContactPage = ({ intl, data }) => {
  return (
    <>
      <SEO
        title="Ниҳол саломатлик сиҳатгоҳи, Санаторий Нихол, Санаторий Учкоргон, Санаторий Узбекистан, Санаторий Наманган, Санаторий Андижан, Санаторий Фергана, Санаторий Ташкент, Санаторий Киргизия, Отдых санаторий Узбекистан, Санаторий Учкурган"
        description="Один из лучших санаториев Узбекистана - Санаторий Нихол, Санаторий Учкоргон, Санаторий Узбекистан, Санаторий Наманган, Санаторий Андижан, Санаторий Фергана, Санаторий Ташкент, Санаторий Киргизия, Отдых санаторий Узбекистан, Санаторий Учкурган, Ниҳол саломатлик сиҳатгоҳи"
      />
      <NavBar />
      <Section>
        <Columns>
          <Column is8>
            <div className="is-shadow">
              <Ymaps height="70vh" />
            </div>
          </Column>
          <Column is4>
            <article className="message is-warning is-shadow">
              <div className="message-body">
                <div className="content">
                  <h3 className="is-size-6-mobile">
                    {intl.formatMessage({
                      id: `footer.col_1.title`,
                    })}
                  </h3>
                  <p>
                    {" "}
                    {intl.formatMessage({
                      id: `footer.col_1.location`,
                    })}
                  </p>
                  <div className="mb-1">
                    <a
                      href="tel:+998953008885"
                      className="button hvr-bounce-to-right is-uppercase is-primary is-outlined has-text-weight-bold is-small has-family-pt-sans mr-05"
                    >
                      {intl.formatMessage({
                        id: `footer.col_1.phone`,
                      })}
                    </a>
                    <a
                      href="tel:+998945001096"
                      className="button hvr-bounce-to-right is-uppercase is-primary is-outlined has-text-weight-bold is-small has-family-pt-sans"
                    >
                      {intl.formatMessage({
                        id: `footer.col_1.phone2`,
                      })}
                    </a>
                  </div>
                </div>
              </div>
            </article>
            <article className="message is-danger is-shadow">
              <div className="message-body">
                <p>
                  {intl.formatMessage({
                    id: `treatment.attention.title`,
                  })}
                </p>
                <br />
                <p>
                  {intl.formatMessage({
                    id: `treatment.attention.desc-1`,
                  })}
                </p>
                <br />
                <p>
                  {intl.formatMessage({
                    id: `treatment.attention.desc-2`,
                  })}
                </p>
              </div>
            </article>
          </Column>
        </Columns>
      </Section>
      <Footer data={data.section.edges[0]} />
    </>
  )
}

export const ContactPageQuery = graphql`
  query {
    section: allProductsYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

export default injectIntl(ContactPage)
